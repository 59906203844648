.sponsors-main {
  position: relative;
  /* height: 100vh; */
  background-color: #1c1f35;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.4);
}

.title-container {
  margin-top: 8rem !important;
  margin-bottom: 2rem;
}

.sponsor-image {
  transform: scaleX(-1);
  filter: FlipH;
  -ms-filter: "FlipH";
  float: none;
}

.sponsors-container {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.sponsors-main h2 {
  text-align: left;
}

.sponsors-title {
  color: #fff;
}

.sponsors-details {
  color: #fff;
}

.sponsers {
  display: flex;
  flex-direction: column;
  /* justify-content: space-around; */
  gap: 3rem;
}

.sponserContainer {
  gap: 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.img {
  padding: 2rem;
  max-width: 250px;
}

.sponsors-heading {
  font-weight: 1000;
  color: rgba(83, 93, 161, 0.8);
}

.sponser-title {
  color: white;
  text-shadow: 1px 1px 5px #fff;
  text-align: center;
}
