.card:hover {
  box-shadow: 5px 10px 20px 1px rgba(255, 255, 255, 0.273) !important;
  transition: all 0.2s linear;
}

.card-body {
  padding: 1rem 0 !important;
}

.linkss {
  font-size: 2rem;
  margin: 0 1rem;
  padding: 0;
}

.card-text {
  /* padding: 0.3rem 1rem; */
  font-size: 1rem;
}

.overflow {
  overflow: hidden;
}

.card-img-top {
  /* height: 450px; */
  /* width: 300px; */
  object-fit: cover;

  transform: scale(1);
  transition: all 0.2s linear;
}

.card-img-top:hover {
  transform: scale(1.1);
}
