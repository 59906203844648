a {
  text-decoration: none !important;
  color: white !important;
}
.footer{
  min-height: 11rem;
  background-color: #1c1f35;

  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.6);
  /* margin-bottom: 1rem; */
  padding-bottom: 1rem;
  padding-top: 1rem;

  position: relative;
}

.more-links{
  letter-spacing: 2px;
  /* margin-bottom: 1rem; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
}

.more-links span{
  margin-top: 1rem;
}

.footer-icons{
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  font-size: 3rem;
}
.map{
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.map > iframe {
  opacity: 0.9;
  border-radius: 10px;
  margin: 1rem;
  width: 100%;
  height: 200px;
}

@media only screen and (max-width: 600px) {
  .footer{
    min-height: 12rem;
  }

  .footer-icons{
    font-size: 2rem;
  }
  .more-links{
    margin: 2rem;
  }
  
a {
  text-decoration: none !important;
  color: white !important;
}
}