.crew-main {
  position: relative;
  background-color: #1c1f35;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.4);
  color: white;
  padding-top: 5%;
  /* padding-bottom: 5%; */
  /* height: 100vh; */
}

.crew-heading {
  text-align: end;
  font-weight: 1000;
  color: rgba(83, 93, 161, 0.8);
}

.crew_image {
  transform: scaleX(-1);
  filter: FlipH;
  -ms-filter: "FlipH";
  float: none;
  width: 80%;
}

@media only screen and (min-width: 992px) {
  .carousel-item img {
    object-fit: cover;
    margin: auto;
  }

  .carousel-inner {
    width: 60% !important;
    margin: auto;
    align-items: center;
    text-align: center;
  }
}
