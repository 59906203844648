.speaker-main {
  position: relative;
  background-color: #1c1f35;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.4);
  color: white;
  padding-top: 5%;
  padding-bottom: 5%;
}

.speaker-heading-div {
  height: 6rem;
  position: relative;
}

.title-container-speakers {
  margin-top: 2rem !important;
}

.speaker-heading {
  /* position: absolute; */
  /* text-align: center; */
  /* top: 2rem; */
  /* right: 3.5rem; */
  /* left: 1rem; */
  font-weight: 1000;
  color: rgba(83, 93, 161, 0.8);
  /* width: 100%; */
  /* margin-left: auto; */
}

.speaker-container {
  position: relative;
  width: 330px;
  min-height: 250px;
  margin: 1.5rem 0.5rem;
  align-items: center;
  display: flex;
  justify-content: center;
}

.speakers-card {
  position: absolute;
  width: 100%;
  height: 100%;
}

.speaker {
  padding-bottom: 2.5rem;
}
