.navbar {
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.4);

  background-color: #1c1f35;
}

.navtitle {
  color: #fff;
  padding: 0%;
  margin: 0;
}

.navlinks {
  color: #fff;
  padding: 0 2rem;
}

.navbar-toggler-icon {
  color: rgb(255, 102, 203) !important;
  border: white 2;
}

/* .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27%3e%3cpath stroke=%27rgba%28255, 255, 255, 1%29%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e") !important;
} */

.navbar-toggler-icon {
  background-image: url("../../images/hamburger_icon.png") !important;
}


#mlh-trust-badge {
  display: block;
  max-width: 100px;
  min-width: 60px;
  position: fixed;
  right: 100px;
  top: 0;
  width: 10%;
  z-index: 10000;
}

@media only screen and (min-width: 992px) {
  #mlh-trust-badge {
    display: block;
    max-width: 100px;
    min-width: 60px;
    position: fixed;
    right: 10px;
    top: 0;
    width: 10%;
    z-index: 10000;
  }
}
.navbar-toggler {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}
