.prizes-main {
  position: relative;
  background-color: #1c1f35;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.4);
  color: white;
  padding-top: 5%;
}

.prize-heading-div {
  height: 6rem;
  position: relative;
}

.prize-image {
  transform: scaleX(-1);
  filter: FlipH;
  -ms-filter: "FlipH";
  float: none;
}

.prizes-heading {
  position: absolute;
  text-align: center;
  top: 2rem;
  /* right: 1rem; */
  /* left: 3.5rem; */
  font-weight: 1000;
  color: rgba(83, 93, 161, 0.8);
  width: 100%;
  margin-left: auto;
}

.prize-img {
  position: absolute;
}

.prize {
  padding: 1.5rem;
  transition: all 0.2s ease-in-out;
  border-radius: 44px;
  background: #161723;
  box-shadow: 12px 12px 5px #0c121e;
}

.prize:hover {
  box-shadow: 20px 20px 32px #0d0e18, -20px -20px 32px #0d0e18;
}

.prize-title {
  color: #fff;
  font-size: 25px;
  text-align: center;
}

.prize-amt {
  color: #fff;
  font-size: 40px;
  text-align: center;
  text-shadow: 2px 2px 6px #fff;
}

.prizes {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 3rem;
  margin-bottom: 1rem;
}

.prize-1 {
  display: flex;
  justify-content: center;
}

.prize-container {
  padding: 0.5rem;
}

.prize3 h5 {
  font-size: 20px;
}

.prize3 p {
  font-size: 35px;
}

.prize3_2 {
  margin-top: 1.5rem;
}

.prize-tag {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 2rem;
}

.flux {
  color: #426dfb;
  font-size: 2.5rem;
  text-shadow: 0 0 3vw #2356ff;
}

@media only screen and (min-width: 992px) {
  .prizes-main {
    position: relative;
    background-color: #1c1f35;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.4);
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* height: 100vh; */
    padding: 1rem;
  }

  .prizes-heading {
    position: absolute;
    text-align: start;
    top: 2rem;
    right: 1rem;
    /* left: 3.5rem; */
    font-weight: 1000;
    color: rgba(83, 93, 161, 0.8);
    width: 100%;
    margin-left: auto;
  }

  .prize3_2 {
    margin-top: 0rem;
  }
}

.vibrate-3 {
  animation: vibrate-3 0.5s linear infinite both;
}

@keyframes vibrate-3 {
  0% {
    transform: translate(0);
  }
  10% {
    transform: translate(-2px, -2px);
  }
  20% {
    transform: translate(2px, -2px);
  }
  30% {
    transform: translate(-2px, 2px);
  }
  40% {
    transform: translate(2px, 2px);
  }
  50% {
    transform: translate(-2px, -2px);
  }
  60% {
    transform: translate(2px, -2px);
  }
  70% {
    transform: translate(-2px, 2px);
  }
  80% {
    transform: translate(-2px, -2px);
  }
  90% {
    transform: translate(2px, -2px);
  }
  100% {
    transform: translate(0);
  }
}
