.track-main {
  position: relative;
  background-color: #1c1f35;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.4);
  color: white;
  padding-top: 5%;
  /* height: 100vh; */
}

.track-heading-div {
  height: 6rem;
  position: relative;
}

.tracks-image {
  transform: scaleX(-1);
  filter: FlipH;
  -ms-filter: "FlipH";
  float: none;
}

.title-container-tracks {
  margin-top: 2rem !important;
}

.track-heading {
  /* position: absolute; */
  text-align: end;
  /* top: 2rem; */
  /* right: 3.5rem; */
  /* left: 1rem; */
  font-weight: 1000;
  color: rgba(83, 93, 161, 0.8);
  /* width: 100%; */
  /* margin-left: auto; */
}

.track-img {
  position: absolute;
}

.track-container {
  position: relative;
  width: 330px;
  min-height: 250px;
  border: 2px solid white;
  border-radius: 2rem;
  margin: 1.5rem 0.5rem;
  box-shadow: 2px 2px 12px 3px rgba(255, 255, 255, 0.2);
}

.track-card-container {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
.track-card {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 2rem;
  transform-style: preserve-3d;
  transition: all 1s ease;
}

.track-container:hover .track-card {
  transform: rotateY(180deg);
}

.card-front {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 2rem;
  backface-visibility: hidden;
  transform: rotateX(0deg);
}
.card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 2rem;
  backface-visibility: hidden;
  transform: rotateX(0deg);
  transform: rotateY(180deg);
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.card-front-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.track-para {
  margin-top: 1rem;
  letter-spacing: 1.2px;
  font-weight: 700;
  font-size: 1rem;
  text-align: center;
}

.card-title {
  text-align: center;
  font-weight: bold;
  text-shadow: 0 0 7px rgb(244, 241, 255);
}

.track-heading-container {
  margin-top: 2rem;
}

/* @media only screen and (min-width: 992px) {
  .track-main {
    position: relative;
    background-color: #1c1f35;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.4);
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
    padding: 1rem;
  }

  .track-container {
    position: relative;
    width: 250px;
    min-height: 200px;
    border: 2px solid white;
    border-radius: 2rem;
    margin: 1.5rem 2.5rem;
    box-shadow: 2px 2px 12px 3px rgba(255, 255, 255, 0.2);
  }

  .track-heading {
    position: absolute;
    text-align: end;
    top: 2rem;
    right: 3.5rem;
    left: 1rem;
    font-weight: 1000;
    color: rgba(83, 93, 161, 0.8);
    width: 100%;
    margin-left: auto;
  }
} */
