.faq-main {
  position: relative;
  padding: 5%;
  background-color: #1c1f35;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.4);
}

.faq-image {
  transform: scaleX(-1);
  filter: FlipH;
  -ms-filter: "FlipH";
  float: none;
}

.faq-heading {
  /* position: absolute; */
  /* text-align: center; */
  /* top: 2rem; */
  /* right: 3.5rem; */
  /* left: 2.5rem; */
  font-weight: 1000;
  color: rgba(83, 93, 161, 0.8);
  /* width: 100%; */
  /* margin-left: auto; */
}

.faq-container {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.faq-title {
  color: #fff;
  text-align: center;
}

.faq-details {
  color: #fff;
}

.faq-heading-container {
  margin-top: 2rem;
}

.faq-heading-div {
  height: 6rem;
  position: relative;
}

.faq-img {
  position: absolute;
}

.accordion {
  max-height: 80vh;
  overflow-x: hidden;
  overflow-y: scroll;
  /* width: 74rem; */
  max-width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}
.accordion::-webkit-scrollbar {
  /* WebKit */
  width: 0;
  height: 0;
}

.item {
  background-color: #212751;
  /* min-width: 1000px;
  max-width: 1000px; */
  width: 100%;
  /* margin-top: 1.8rem; */
  /* margin-left: 14rem; */
  /* margin-bottom: 1rem; */
  padding: 1rem 2.5rem;
  border-radius: 1.1rem;
}
.title {
  color: darkslateblue;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
.content {
  font-size: 1rem;
  color: white;
  opacity: 0.6;
  max-height: 0;
  overflow: hidden;
  transition: all 0.4s cubic-bezier(0, 1, 0, 1);
}
.content.show {
  height: auto;
  max-height: 999.9rem;
  transition: all 0.4s cubic-bezier(1, 0, 1, 0);
}

.faq {
  /* color: rgb(194, 192, 192); */
  color: white;
  font-size: medium;
}

.ans {
  color: #fff;
}

.faq-hr {
  color: #fff;
}

@media (max-width: 85rem) {
  .title {
    margin-right: 15px;
  }

  .faq-main {
    /* height: 370vh; */

    padding: 5% 0.5%;
  }

  .faq {
    font-size: 0.8rem;
    text-align: left;
    padding: 2px;
  }
  .content {
    font-size: 0.7rem;
  }
  .accordion {
    width: 80%;
    margin-left: 33px;
    /* height: 90vh; */
    /* overflow-y: scroll; */
    /* overflow-x: hidden; */
  }
  .item {
    text-align: left;
    background-color: #212751;
    margin-top: 10px;
    margin-left: 12.5%;
    /* margin-bottom: 1.5%; */
    padding: 0.7rem 1.2rem;
  }
}

/* @media only screen and (min-width: 992px) {
  .faq-heading {
    position: absolute;
    text-align: start;
    top: 2rem;
    right: 3.5rem;
    left: 2.5rem;
    font-weight: 1000;
    color: rgba(83, 93, 161, 0.8);
    width: 100%;
    margin-left: auto;
  }
} */
