.themes-main {
  position: relative;
  background-color: #1c1f35;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.4);
  color: white;
  padding-top: 5%;
}

.theme-heading-div {
  height: 6rem;
  position: relative;
}

.theme-image {
  transform: scaleX(-1);
  filter: FlipH;
  -ms-filter: "FlipH";
  float: none;
}

.line {
  position: absolute;
}

.themes-heading {
  /* position: absolute; */
  /* text-align: center; */
  /* top: 2rem; */
  /* right: 2rem; */
  /* left: 3.5rem; */
  font-weight: 1000;
  color: rgba(83, 93, 161, 0.8);
  /* width: 100%; */
  /* margin-left: auto; */
}

.prize-img {
  position: absolute;
}

.theme {
  margin: 1rem;
  padding: 3rem;
  transition: all 0.2s ease-in-out;
  border-radius: 44px;
  /* box-shadow: -22px -22px 54px #0c121e, 22px 22px 54px #0c121e; */
  box-shadow: 2px 2px 12px 3px rgba(255, 255, 255, 0.2);
  cursor: pointer;
}

.prize-title {
  color: #fff;
  font-size: 25px;
  text-align: center;
}

.themes-amt {
  color: #fff;
  font-size: 25px;
  text-align: center;
  text-shadow: 2px 2px 6px #fff;
  transition: all 0.2s;
}

.themes {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin-top: 3rem;
  margin-bottom: 1rem;
}

.prize-1 {
  display: flex;
  justify-content: center;
}

.prize-container {
  padding: 0.5rem;
}

#prize3 {
  margin-top: 1rem;
}

.flip-card {
  background-color: transparent;
  width: 300px;
  height: 150px;
  margin: 2rem;
  padding: 0.5rem;
  border: 2px solid #f1f1f1;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.4);
  /* box-shadow: 2px 2px 12px 3px rgba(255, 255, 255, 0.2); */
  border-radius: 44px;
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
}

/* This container is needed to position the front and back side */
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

/* Position the front and back side */
.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

.flip-card-front {
  letter-spacing: 1px;
  font-weight: 800;
  font-size: 1.1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 900;
  transform: rotateX(0deg);
}

.flip-card-back {
  letter-spacing: 1px;
  font-weight: 800;
  font-size: 1.1rem;
  color: white;
  transform: rotateY(180deg);
  display: flex;
  justify-content: center;
  align-items: center;
}

/* @media only screen and (min-width: 992px) {
  .themes-main {
    position: relative;
    background-color: #1c1f35;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.4);
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
    padding: 1rem;
  }
  .themes-heading {
    position: absolute;
    text-align: start;
    top: 2rem;
    right: 2rem;
    left: 3.5rem;
    font-weight: 1000;
    color: rgba(83, 93, 161, 0.8);
    width: 100%;
    margin-left: auto;
  }

  #prize3 {
    margin-top: 0;
  }
} */
