.landing-btn:hover {
  background-color: rgba(45, 53, 104, 0.8);
}

.test {
  display: flex;
  color: white !important;
  /* font-family: Arial, Helvetica, sans-serif !important; */
  font-family: "Ubuntu", sans-serif !important;
  letter-spacing: 6px !important;
  font-size: 40px !important;
  font-weight: 600 !important;
}

.test::before {
  content: "2022";
  position: absolute;
  color: rgba(255, 255, 255, 0.246);
  font-size: 150px;
  margin-top: -50px;
  left: calc(50% - 160px);
  /* font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; */
  /* font-family: 'Anton', sans-serif !important; */
  font-family: "Ubuntu", sans-serif !important;

  font-weight: 400;
  text-align: center;
  margin-top: -60px;
  margin: left -30px;
  left: -40px;
}

/* Shutter In Vertical */
.hvr-shutter-in-vertical {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  /* background: #2098D1; */
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  border-radius: 20px;
}
.hvr-shutter-in-vertical:before {
  border-radius: 20px;
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(83, 93, 161, 0.8);
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
  -webkit-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hvr-shutter-in-vertical:hover,
.hvr-shutter-in-vertical:focus,
.hvr-shutter-in-vertical:active {
  background-color: white;
  color: rgba(83, 93, 161, 0.8);
  /* color: white; */
}
.hvr-shutter-in-vertical:hover:before,
.hvr-shutter-in-vertical:focus:before,
.hvr-shutter-in-vertical:active:before {
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
}

.landing-main {
  /* making the background a bit dark  */
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.4);

  /* animation: transitionIn 1.5s; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 90vh;
  background-color: #1c1f35;
  /* background-image: url("../../images/background.png"); */
  background-size: cover;
  background-position: center;
  min-height: 100%;
  /* position: relative; */
}

.name {
  /* pointer-events:none; */
  cursor: pointer;
  /* animation: transitionIn 1.5s; */
  /* background-color: rgba(83,93,161,0.8); */
  color: white;
  border-radius: 20px;
  width: 20rem;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  font-weight: 1000;
  letter-spacing: 4px;
}

.main-buttons {
  margin: 2rem auto;
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.discord-btn {
  width: 20rem !important;
  background-color: #2356ff !important;
}

.main-buttons button {
  font-size: 15px;
  font-weight: bold;
  border: #1c1f35;
  color: white;
  border-radius: 10px;
  width: 14rem;
  height: 3rem;
  margin: 0.5rem;

  background: rgba(255, 255, 255, 0.2);
  background-color: rgba(83, 93, 161, 0.8);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(11px);
  border-radius: 10px;
  border: 2px solid rgba(255, 255, 255, 0.18);
}

.name-2 {
  /* animation: transitionIn 1.5s; */
  margin: 1rem auto;
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 15rem; */
  height: 4rem;
  /* background-color: rgba(83,93,161,0.8); */
  color: white;
  border-radius: 10px;
  font-size: 15px;
  font-weight: 1000;
  letter-spacing: 4px;
}

.sm-headings {
  flex-direction: column;
  display: flex;
  /* width: max-content; */
  /* justify-content: flex-start; */
  align-items: center;
  /* color: white; */
  margin-bottom: 2rem;
}

.sm-headings > h4 {
  padding: 0 1rem;
  /* font-size: 1.2rem !important; */
}

.flux {
  /* font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; */
  /* font-family: neon; */
  color: #426dfb;
  font-size: 1.2rem;
  /* line-height: 9vw; */
  text-shadow: 0 0 3vw #2356ff;
}

@media only screen and (min-width: 992px) {
  .sm-headings {
    position: absolute;
    top: 2rem;
    left: 2rem;
  }
  .sm-headings > h4 {
    font-size: 2rem;
  }

  .flux {
    animation: flux 2s linear infinite;
    -moz-animation: flux 2s linear infinite;
    -webkit-animation: flux 2s linear infinite;
    -o-animation: flux 2s linear infinite;
  }

  @keyframes flux {
    0%,
    100% {
      text-shadow: 0 0 1vw #1041ff, 0 0 3vw #1041ff, 0 0 10vw #1041ff,
        0 0 10vw #1041ff, 0 0 0.4vw #8bfdfe, 0.5vw 0.5vw 0.1vw #147280;

      color: white;
    }
    /* 50% {
      text-shadow: 0 0 0.5vw #082180, 0 0 1.5vw #082180, 0 0 5vw #082180,
        0 0 5vw #082180, 0 0 0.2vw #082180, 0.5vw 0.5vw 0.1vw #0a3940;

      color: white;
    } */
  }

  .test {
    color: white !important;
    font-family: Arial, Helvetica, sans-serif !important;
    letter-spacing: 6px !important;
    font-size: 80px !important;
    font-weight: 800 !important;
  }

  .test::before {
    content: "2022";
    position: absolute;
    color: rgba(255, 255, 255, 0.246);
    font-size: 150px;
    margin-top: -50px;
    left: calc(50% - 160px);
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
      sans-serif;
    font-weight: 400;
    text-align: center;
  }

  /* .name:hover{
  color: rgba(83,93,161,0.8);
  background-color: white;
  transition: 0.5s;
  } */

  .name-2 {
    /* animation: transitionIn 1.5s; */
    margin: 1rem auto;
    display: flex;
    justify-content: center;
    align-items: center;
    /* width: 15rem; */
    height: 4rem;
    /* background-color: rgba(83,93,161,0.8); */
    color: white;
    border-radius: 10px;
    font-size: 25px;
    font-weight: 1000;
    letter-spacing: 4px;
  }

  .main-buttons {
    /* margin: 2rem auto; */
    width: 40%;
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    align-items: center;
  }

  .main-buttons button {
    font-size: 20px;
    border-radius: 5px;
    width: 15rem;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .name {
    border-radius: 30px;
    width: 35rem;
    height: 6rem;
    font-size: 50px;
  }
}

.landing-main {
  position: relative;
  height: 90vh;
  background-color: #1c1f35;
}

.atag {
  text-decoration: none;
}

@media only screen and (max-width: 992px) {
  .test::before {
    top: -50px;
    font-size: 115px;
    left: -10px;
  }
}

@media only screen and (max-width: 600px) {
  .flux {
    color: white;
  }

  .sm-headings {
    margin-bottom: 6rem;
    flex-direction: column;
  }
  .main-buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 0;
    align-items: center;
  }
}

/* copied */

@font-face {
  font-family: neon;
  src: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/707108/neon.ttf);
}

@media only screen and (max-height: 700px) {  
  .sm-headings h4{
    font-size: 1rem;
  }
}